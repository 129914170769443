import React, { Component } from "react";
import "./promise-container.scss";
import { Container, Row, Col } from "react-bootstrap";
import PromiseCard from "./promise-card";
import PromiseMapCard from "./promise-map-card/promise-map-card";
import smartContractImage from "./smart-contract.png";
import metaverse from "./metaverse.png";
import access from "./access.png";
import quests from "./quests.png";
import { Link } from "react-router-dom";

import Pdf from "../../assets/lightpaper.pdf";

class PromiseContainer extends Component {
  openWhitePaperPage = () => {
    window.open(Pdf, "_blank");
  };

  render() {
    return (
      <Container
        className="promise-container container d-flex flex-column justify-content-center"
        fluid={true}
      >
        <div className="map-container d-flex flex-nowrap flex-lg-row">
          <PromiseMapCard to="/map" imageIndex={1} text={"Map"} />

          <PromiseMapCard to="/breeds" imageIndex={2} />

          <div className="clickable" onClick={this.openWhitePaperPage}>
            <PromiseMapCard imageIndex={5} />
          </div>
        </div>
      </Container>
    );
  }
}

export default PromiseContainer;
