import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import './elder-card.scss';
class ElderCard extends Component {
    render() {

      return (
          <div className={this.props.mode === 'lastrow' ? "elder-card elder-card-last-row" : "elder-card"}>
           <div className="elder-card-container">
               <img src={this.props.image} />
               {/* <div className="mt-3">
                   {this.props.text}
               </div> */}
           </div>
          </div>
      )
    }
}

export default ElderCard;
