import React, {Component} from 'react'
import './promise-map-card.scss';
import { Link } from 'react-router-dom';

class PromiseMapCard extends Component {
    render() {

        const {imageIndex = 1, title = '', to, locking} = this.props

        const promiseCard = (locking) => (
            <div className={`promise-map-card ${locking ? 'unclickable' : ''}`}>
                <div className={`item item-image image-${imageIndex}`}></div>
                {/* <div className="d-flex flex-column"> */}
                    {/*<p>{text}</p>*/}
                    {/* <hr></hr>
                    <i class="bi bi-box-arrow-up-right"></i> */}
                {/* </div> */}
            </div>
        )

        return (
            to ? (
                <Link to={to} title={title}>
                    {promiseCard(locking)}
                </Link>
            ) : (
                promiseCard(locking)
            )
        )
    }
}

export default PromiseMapCard;
