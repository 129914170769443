import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss'

const Loadingcomponent = props => {
    return (
        <div className="full-screen">
            <div className="lds-dual-ring"></div>
        </div>
    );
};

Loadingcomponent.propTypes = {

};

export default Loadingcomponent;
