import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./loner.scss";
import ImageLoner from "./background-loner.png";
import { HeaderTitle } from "../shared/HeaderTitle";

class Loner extends Component {
  render() {
    return (
      <div className="loner-page">
        <Container>
          <img src={ImageLoner} />
          <div className="text">
            You need a Wonderquest NFT to access the album.
            <br />
            Buy here on opensea.
          </div>
        </Container>
      </div>
    );
  }
}

export default Loner;
