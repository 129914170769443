import React, { Component } from 'react'
import './promise-container.scss';
import promiseCardBottomImage from './deco@1.5x.png';

class PromiseCard extends Component {
    render() {

      return (
          <div className="text-center promise-cart-item">
              <div className="promise-card d-inline-block">
                  <img src={this.props.image} className="card-header-image"/>
                  <h4 className="card-heading">{this.props.title}</h4>
                  <p className="card-description">{this.props.description}</p>
              </div>
          </div>
      )
    }
}

export default PromiseCard;
