import React from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Router,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import "./App.css";
import "./styles/index.scss";
import Home from "./components/home/home";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Breeds from "./components/breeds/breeds";
import Map from "./components/map/map";
import Loner from "./components/loner/loner";
import Update from "./components/update/update";
import Inventory from "./components/inventory";
import WhitePaper from "./components/whitepaper/whitepaper";
import { Web3ReactProvider, createWeb3ReactRoot } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import "react-toastify/dist/ReactToastify.css";

import Pdf from "./assets/lightpaper.pdf";

const history = createBrowserHistory();

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Router history={history}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Header />
            <div className="app-content">
              <Switch>
                <Route
                  path="/"
                  exact
                  component={() => {
                    return <Redirect to="/home" />;
                  }}
                />
                <Route path="/home" exact component={Home} />
                <Route path="/hatch" exact component={Inventory} />
                <Route path="/map" exact component={Map} />
                <Route path="/loner" exact component={Loner} />
                <Route path="/update" exact component={Update} />
                <Route path="/breeds" exact component={Breeds} />
                <Route
                  path="/lightpaper"
                  component={() => {
                    window.open(Pdf, "_blank");
                    return <Redirect to="/home" />;
                  }}
                />
              </Switch>
            </div>

            <Footer />
          </Web3ReactProvider>
        </Router>
      </BrowserRouter>
    </div>
  );
}

export default App;
