import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./map.scss";
import mainMap from "./map.png";
import { HeaderTitle } from "../shared/HeaderTitle";

class Map extends Component {
  render() {
    return (
      <div className="map-page">
        <div className="top">
          <div className="bottom"></div>
        </div>
        <Container className="map-container container">
          <HeaderTitle title="The Map" />
          <div className="img">
            <img src={mainMap} width="90%" alt="Main Map Image" />
          </div>
        </Container>
        {/* <PromiseContainer /> */}
      </div>
    );
  }
}

export default Map;
