import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import mainImage from './assets/images/background.svg';
import image7 from './assets/images/artboard-7.png';
import logo1 from './assets/images/logo-1.png';
import logo2 from './assets/images/logo-2.png';
import logo3 from './assets/images/logo-3.png';
import logo4 from './assets/images/logo-4.png';
import logo5 from './assets/images/logo-5.png';
import './assets/scss/whitepaper.scss';

class WhitePaper extends Component {
    render() {
        return (
            <div className="whitepaper-main-div">

                <section className="section">
                    <Container>
                        <Row>
                            <Col>
                                <img src={mainImage} alt="wonderquest-mainImage" />
                            </Col>
                        </Row>
                        <Row className="main-heading-container">
                            <Col xs={12}>
                                <h4 className="sub-heading">Alison Wonderland presents the WonderQuest</h4>
                            </Col>
                            <Col xs={12}>
                                <h1 className="heading">NFT White paper 2021</h1>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section__text-image">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={6}>
                                <div className="box-heading">
                                    <h4 className="sub-heading">THE WONDERQUEST</h4>
                                    <h2 className="heading">WHAT IS THIS?</h2>
                                </div>

                                <div className="box-content">
                                    A Global team of artists, builders, thinkers and dreamers set out to fuse together  art, music, community and gaming to create an unparalleled experience of amplified escapism that the metaverse has never before seen.
                                </div>

                                <div className="box-content">
                                    It starts with an egg. What's inside? Do I hatch it? Questions only you can  answer. You can either collect it as a rare egg OR hatch it and reveal what’s  inside. The choice is yours.
                                </div>

                                <div className="box-image">
                                    <img src={mainImage} alt="wonderquest logo small" />
                                </div>
                            </Col>
                            <Col lg={6} className="text-center">
                                <img src={mainImage} class="main-image" alt="wonderquest logo small" />
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section__text-image">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={6}>
                                <div className="box-heading">
                                    <h4 className="sub-heading">THE WONDERQUEST</h4>
                                    <h2 className="heading">WHY ARE WE DOING THIS?</h2>
                                </div>

                                <div className="box-content">
                                    <p>
                                        A personal message from our Chief Creator, Architect, Author and Wondertown ruling  Queen.
                                    </p>
                                    <p>
                                        <b>Alison Wonderland</b>
                                    </p>
                                    <ul>
                                        <li>Electronic dance music producer, DJ, and singer</li>
                                        <li>Australian-born LA-based</li>
                                        <li>Classically trained cellist</li>
                                        <li>900k Instagram followers, 320K Twitter followers</li>
                                        <li>Highest billed female DJ in Coachella history.</li>
                                        <li>#1 Billboard Top Dance/Electronic Albums (2x)</li>
                                        <li>500m+ streams by 2020 across Digital Service Providers [Spotify, iTunes and others]</li>
                                        <li>10m+ downloads of Radio Wonderland show on iTunes</li>
                                        <li>Merch - FMUOASL Brand that regularly sells out drops in 2-4 hours. HAs had collabs with Forever 21, Fig and Viper, Asics.</li>
                                    </ul>
                                </div>

                                <div className="box-image">
                                    <img src={mainImage} alt="wonderquest logo small" />
                                </div>
                            </Col>
                            <Col lg={6} className="text-center">
                                <img src={mainImage} class="main-image" alt="wonderquest logo small" />
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section__text-image">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={6}>
                                <div className="box-heading">
                                    <h4 className="sub-heading">THE WONDERQUEST</h4>
                                    <h2 className="heading">ALISON WONDERLAND’S CURATED EXPERIENCES</h2>
                                </div>

                                <div className="box-content">
                                    <p>
                                        AW creates global events that combine entertainment and experience with her Wonderland Project.
                                    </p>
                                    <p>
                                        <b>Alison Wonderland</b>
                                    </p>
                                    <ul>
                                        <li>Wonderland Warehouse Project 1.0, featuring Australian artist lineup with AW headlining in Melbourne and Sydney. 10,000 tickets sold</li>
                                        <li>Wonderland Warehouse Project 2.0, featuring Australian artist line up with AW headling nationally in Australia. Over 35,000 tickets sold.</li>
                                        <li>Wonderland Warehouse Project 3.0, featuring internal and local lineup [A$AP Ferg], a national Australian Tour that also went international [NZ, LA, NYC, Japan, Berlin, Russia]</li>
                                        <li>Currently headlining Lollapalooza, 2 sold out Red Rocks shows &amp; Austin City Limits Festivals.</li>
                                    </ul>
                                </div>

                                <div className="box-image">
                                    <img src={mainImage} alt="wonderquest logo small" />
                                </div>
                            </Col>
                            <Col lg={6} className="text-center">
                                <img src={mainImage} class="main-image" alt="wonderquest logo small" />
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section__heading-copy">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="box-heading">
                                    <h4 className="sub-heading">THE WONDERQUEST</h4>
                                    <h2 className="heading">INTRODUCTION</h2>
                                </div>

                                <div className="box-content">
                                    <p>The WonderQuest is Alison Wonderland’s odyssey of exploration in the digital world; a treasure hunt of experiences in the metaverse. </p>

                                    <p>An opportunity awaits to embark on a journey of epic proportions - for the first time ever, join AW’s odyssey featuring her music in a virtual landscape. The adventure ahead will allow you to explore never-before-seen parts of the metaverse and also interact with it in ways not possible. Until now.</p>

                                    <p>Join Alison Wonderland on the WonderQuest by purchasing one or several of the 10,000 unique, mysterious and mystical eggs. Minting is fully randomised and there are 12 different dragon breeds, all of varying rarity. Hatch your egg, or wait, the choice is yours. Unleash your creation upon this world or keep the power within.</p>

                                    <p>The egg is the beginning of a long and wonderful journey. Together we quest. WonderQuest.</p>
                                </div>

                                <div className="box-image">
                                    <img src={mainImage} alt="wonderquest logo small" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section class="section section__project-team">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="box-heading">
                                    <h4 className="sub-heading">THE WONDERQUEST TEAM</h4>
                                    <h2 className="heading">THE PROJECT TEAM.<br />WHO ARE WE?</h2>
                                </div>

                                <div className="box-content">
                                    <p>Led by Alison Wonderland, a worldwide collective of artists, builders, and dreamers join forces to deliver a metaverse experience like no other and where the whole is undoubtedly greater than the sum of its parts.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="wrap-project-teams justify-content-center">
                            {
                                [
                                    {
                                        image: logo1,
                                        title: "Alison Wonderland",
                                        description: "<p>Chief Creator, Architect, Author and Wondertown ruling Queen.</p>"
                                    },
                                    {
                                        image: logo2,
                                        title: "Run It Wild",
                                        description: "<p>Metaverse Strategy & Partnerships</p><p>Orchestrate creators, brands, IP specialists, smart contract romantics and metaverse enthusiasts.</p>"
                                    },
                                    {
                                        image: logo3,
                                        title: "NFT STUDIOS<br/>Smart Contract",
                                        description: "<p>Building the Metaverse. One block at a time.</p>"
                                    },
                                    {
                                        image: logo4,
                                        title: "CandyAcne<br/>Creative/ Art",
                                        description: "<p>Inspired by the retro anime aesthetic, attention to detail is her key attribute/burden.</p>"
                                    },
                                    {
                                        image: logo5,
                                        title: "Development Fund",
                                        description: "<p>Allocated funding for future development.</p>"
                                    }
                                ].map(item => {
                                    return <div className="project-team">
                                        <div className="box-item">
                                            <div className="box-logo">
                                                <img src={item.image} alt={item.title} />
                                            </div>
                                            <h5 className="title" dangerouslySetInnerHTML={{ __html: item.title }} />
                                            <div className="description" dangerouslySetInnerHTML={{ __html: item.description }} />
                                        </div>
                                    </div>
                                })
                            }
                        </Row>
                    </Container>
                </section>

                <section class="section section__heading-copy">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="box-heading">
                                    <h4 className="sub-heading">THE WONDERQUEST</h4>
                                    <h2 className="heading">NFT DETAILS AND TOKENOMICS</h2>
                                </div>

                                <div className="box-content">
                                    <img src={image7} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section class="section section__heading-copy">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="box-heading">
                                    <h4 className="sub-heading">THE WONDERQUEST</h4>
                                    <h2 className="heading">ROADMAP</h2>
                                </div>

                                <Row className="box-content-grid">
                                    <Col lg={4} xs={12} className="grid-item">
                                        <p>
                                            <b>WHERE WE ARE TODAY:</b> <br />
                                            <span className="color-white">Dream to reality</span> <br />
                                            <em>Nov 2020 to Aug 2021</em>
                                        </p>

                                        <ul>
                                            <li>NFT release 22 July.</li>
                                            <li>Virtual clubhouse in Decentraland</li>
                                            <li>Hosted parties, events and important roundtable discussions</li>
                                            <li>Metaverse music performances, releases, listening parties and more</li>
                                            <li>Airdrops: avatar wearables</li>
                                            <li>Exclusive quests and hosted events</li>
                                        </ul>
                                    </Col>

                                    <Col lg={4} xs={12} className="grid-item">
                                        <p>
                                            <b>WHAT’S NEXT:</b> <br />
                                            <span className="color-white">Diversification and enrichment</span> <br />
                                            <em>September to Dec 2021</em>
                                        </p>

                                        <ul>
                                            <li>Partner with virtual worlds, brands and blockchain games to enrich Wonderquest experience, e.g. The SandBox</li>
                                            <li>Host AW headline and curated line ups; perform regularly in Decentraland</li>
                                            <li>Expand narrative, more Quests and community driven projects</li>
                                        </ul>
                                    </Col>

                                    <Col lg={4} xs={12} className="grid-item">
                                        <p>
                                            <b>WHAT’S NEXT:</b> <br />
                                            <span className="color-white">Multi-metaverse expansion</span> <br />
                                            <em>September to Dec 2021</em>
                                        </p>

                                        <ul>
                                            <li>Expand with AR/VR experiences</li>
                                            <li>Play-to-earn RPG experiences</li>
                                            <li>More exclusive quests and hosted events</li>
                                            <li>Comics. Commercial rights; stories will be written, those on the quest will benefit.</li>
                                        </ul>
                                    </Col>
                                </Row>

                                <div className="box-image">
                                    <img src={mainImage} alt="wonderquest logo small" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section class="section section__heading-copy">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="box-heading">
                                    <h4 className="sub-heading">THE WONDERQUEST</h4>
                                    <h2 className="heading">WHERE TO FROM HERE?</h2>
                                </div>

                                <div className="box-content">
                                    <p class="text-38">The future is being written now <br /> and those on the quest will be rewarded.</p>
                                    <p>Be part of the flagship first series.<br />
                                        Be involved early and benefit from growth.<br />
                                        You’re all invited.</p>
                                    <p class="text-38">Join us on the quest. The WonderQuest.</p>
                                </div>

                                <div className="box-image">
                                    <img src={mainImage} alt="wonderquest logo small" />
                                </div>

                                <div className="box-social">

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section class="section section__heading-copy text-left">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="box-heading">
                                    <h4 className="sub-heading">THE WONDERQUEST</h4>
                                    <h2 className="heading">KEY CONCEPTS & RESOURCES</h2>
                                </div>

                                <div className="box-content">
                                    <p>The availability and accessibility of technology enablers means that the future of art, currency, community, gaming and collectibles is here now. Here is a curated list of the key concepts used throughout this white paper.</p>

                                    <p className="fs-normal">Blockchain - a data structure that holds transactional records and while ensuring security, transparency, and decentralization. You can also think of it as a chain or records stored in the forms of blocks which are controlled by no single authority.</p>

                                    <p className="fs-normal">Decentraland is an Ethereum-based blockchain platform where users can purchase, build and monetize virtual reality applications.</p>

                                    <p className="fs-normal">Ethereum - Ethereum is open access to digital money and data-friendly services for everyone – no matter your background or location. It's a community-built technology behind the cryptocurrency ether (ETH) and thousands of applications you can use today.</p>

                                    <p className="fs-normal">Hatching - The smart contract mechanic that allows the holder to exchange their egg for a dragon. It is the holders decision to hatch or not to hatch.</p>

                                    <p className="fs-normal">GWEI Fees - Ethereum transaction fees, known as Gas Fees. When you mint your egg you will incur a gas fees, when you hatch your egg, you will incur a gas fee. When you sell your egg on OpenSea market place, you will incur a gas fee. These are payments made by users to compensate for the computing energy required to process and valid, rangin from a few dollars [$US] to ten’s of dollars pending the demand on the blockchain at the moment in time. You can wait and hold out, but run the risk of missing out also. Our prayers are with the gas lords to be kind to us.te transactions on the Ethereum blockchain</p>

                                    <p className="fs-normal">NFT - A non-fungible token is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of digital files.</p>

                                    <p className="fs-normal">Metamask - A cryptocurrency wallet used to interact with the Ethereum blockchain. It allows users to access their Ethereum wallet through a browser extension or mobile app, which can then be used to interact with decentralized applications. This is where your NFTs and Ethereum can be stored.</p>

                                    <p className="fs-normal">Minting -  is the process of creating tokens on the Ethereum blockchain</p>

                                    <p className="fs-normal">ERC721 Smart Contract - ERC721 is a standard for representing ownership of non-fungible tokens, that is, where each token is unique. ERC721 is a more complex standard than ERC20, with multiple optional extensions, and is split across a number of contracts.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </div>
        );
    }
}

export default WhitePaper;
