import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Button, Container } from "react-bootstrap";
import bigEgg from "./assets/egg.png";
import ButtonHatch from "./assets/button-hatch.png";
import EggIcon from "./assets/egg_icon.png";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

import Loadingcomponent from "../loadingcomponent";
import { HeaderTitle } from "../shared/HeaderTitle";
import { ABI_ARRAY, CONTRACT_ADDRESS } from "../../configs/contract";
import { toast } from "react-toastify";

let web3;
let NFTSmartContract;
let address;

const Inventory = (props) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const setSelectedItem = useCallback(
    (id) => {
      setSelectedItems(_.xor([id], selectedItems));
    },
    [selectedItems]
  );

  const web3ReactContext = useWeb3React();
  const { account, chainId, activate, active, error, library, deactivate } =
    web3ReactContext;

  useEffect(() => {
    if (account) {
      loadItems(0);
    }
  }, [account]);

  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const loadWeb3 = useCallback(async () => {
    // if (window.ethereum) {
    //   web3 = new Web3(window.ethereum);
    // await window.ethereum.enable();
    // } else if (window.web3) {
    //   web3 = new Web3(window.web3.currentProvider);
    // }
    web3 = new Web3(window.ethereum);
    if (web3) {
      NFTSmartContract = new web3.eth.Contract(ABI_ARRAY, CONTRACT_ADDRESS);
    } else {
      window.alert(
        "Not connected to a Web3 Wallet! Please install/connect to Metamask on your browser."
      );
    }
    // if (web3) {
    //   const addresses = await web3.eth.getAccounts();
    //   const senderAddress = addresses[0];
    //   address = senderAddress;
    // } else {
    //   window.alert(
    //     "Not connected to a Web3 Wallet! Please install/connect to Metamask on your browser."
    //   );
    // }
  });

  // const loadWeb3ForPage = useCallback(async () => {
  //   setIsLoading(true);
  //   await loadWeb3();
  //   setIsLoading(false);
  // }, [loadWeb3, props]);

  // useEffect(() => {
  //   loadWeb3ForPage();
  // }, []);

  const loadItems = useCallback(async () => {
    if (account) {
      await loadWeb3();
      axios
        .get("https://federation.drp.io/site/check_tokens", {
          params: {
            asset_contract_address: CONTRACT_ADDRESS,
            owner: account,
            format: "json",
            order_direction: "desc",
            limit: 20,
            offset: items.length,
          },
        })
        .then((res) => {
          console.log({ response: res.data.assets });
          if (res.data.assets.length === 0) {
            setHasMore(false);
          }
          setItems([...items, ...res.data.assets]);
        });
    } else {
      setHasMore(false)
    }
  }, [items, account]);

  // useEffect(() => {
  //   loadItems(0);
  // }, []);

  const hatchEggs = useCallback(async () => {
    if (chainId !== 1) {
      return toast.error('Please connect to Ethereum Main Network.');
    }
    await loadWeb3();
    setIsLoading(true);
    NFTSmartContract.methods
      .hatchEggs(selectedItems)
      .send({
        from: account,
      })
      .on("sent", function (send) {
        setIsLoading(true);
      })
      .on("receipt", function (receipt) {
        setIsLoading(false);
        window.alert("Success: Eggs Hatched. You can now headover to OpenSea!");
        loadWeb3();
      })
      .on("error", function (error) {
        setIsLoading(false);
        window.alert("Error: " + JSON.stringify(error.message));
      });
  }, [selectedItems, setIsLoading, isLoading, account, chainId]);

  if (isLoading) return <Loadingcomponent />;

  return (
    <div className="Main inventory-page">
      <div className="top">
        <div className="bottom"></div>
      </div>
      <Container className="inventory-container">
        <HeaderTitle title="Hatch" />
        <div className="des">
          What is this egg? I found a wondering merchant in wondertown and he
          sold me these strange eggs with little but a note on where he found
          them and his thoughts behind what may lie inside. I was told I can
          hatch them, or not. That the choice is mine.
        </div>
      </Container>
      <div className="container img">
        <div className="img-egg">
          <img src={bigEgg} alt="" />
        </div>
        {/* <Button size="lg" className="mint-egg-btn mt-0" onClick={hatchEggs}>
          <span className="gradient-main-text">HATCH</span>
        </Button> */}
        <div className="img-button">
          <img src={ButtonHatch} onClick={hatchEggs} />
        </div>
      </div>

      {items.length > 0 && (
        <div className="egg-container main-btn-area">
          <div className="normal-text">
            EGGS SELECTED: {selectedItems.length}
          </div>
          {/* <Button size="lg" className="mint-egg-btn mt-0" onClick={hatchEggs}>
          <span className="gradient-main-text">HATCH</span>
        </Button> */}
        </div>
      )}

      <InfiniteScroll
        dataLength={items.length} //This is important field to render the next data
        next={loadItems}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={<p style={{ textAlign: "center" }}></p>}
      >
        <div className="d-flex flex-wrap mt-5 mb-5 justify-content-center">
          {items.map((item) => {
            let classes = "egg-icon position-relative";
            if (selectedItems.indexOf(item.token_id) > -1) {
              classes += " active";
            }
            return (
              <div
                key={item.token_id}
                onClick={() => {
                  setSelectedItem(item.token_id);
                }}
                className={classes}
              >
                <img src={item.image_url} width={150} />
                <div className="border-cover position-absolute"></div>
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

Inventory.propTypes = {};

export default Inventory;
