import React, { Component } from "react";
import {
  Container,
  Button,
  InputGroup,
  FormControl,
  Nav,
} from "react-bootstrap";
import "./footer.scss";
import logo from "../home/background.svg";
import textRegister from "./text-register.png";
import { Link } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { CONTRACT_ADDRESS } from "../../configs/contract";

const url =
  "https://runitwild.us6.list-manage.com/subscribe/post?u=af359a606444e92c6c12db09d&amp;id=954133666a";

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div className="custom-form-content">
      <InputGroup>
        <Button onClick={submit} size="sm">
          <img src={textRegister} />
        </Button>
        <FormControl
          id="email"
          ref={(node) => (email = node)}
          type="email"
          placeholder="Enter your e-mail address..."
        />
      </InputGroup>

      {status === "sending" && (
        <div className="mailchip-mess sending">sending...</div>
      )}
      {status === "error" && (
        <div
          className="mailchip-mess error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="mailchip-mess success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
};

const MailChip = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <CustomForm
        status={status}
        message={message}
        onValidated={(formData) => subscribe(formData)}
      />
    )}
  />
);

function Footer() {
  return (
    <div className="footer">
      <Container className="footer-container container" fluid={true}>
        <div className="d-md-flex footer-container-row">
          <div className="mailchip">
            <MailChip />
          </div>

          <div className="logo">
            <Link to="/home" title="Go to home page">
              <img src={logo} />
            </Link>
          </div>

          <div className="social d-flex">
            <Nav.Item>
              <Nav.Link
                href={"http://discord.gg/thewonderquest"}
                target={"_new"}
                eventKey={"http://discord.gg/thewonderquest"}
              >
                <img src="/images/svg/discord.svg" alt="Discord" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="twitter-icon">
              <Nav.Link
                href={"https://twitter.com/thewonderquest"}
                target={"_new"}
                eventKey={"https://twitter.com/thewonderquest"}
              >
                <img src="/images/svg/twitter.svg" alt="Twitter" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="twitter-icon">
              <Nav.Link
                href="https://opensea.io/collection/thewonderquest"
                target={"_new"}
                eventKey={"https://opensea.io/collection/thewonderquest"}
              >
                <img src="/images/svg/opensea.svg" alt="Opensea" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="twitter-icon">
              <Nav.Link
                href="https://looksrare.org/collections/0x08bEBEB5f042CCbaEb128582DA560cb25a5dB7e9"
                target={"_new"}
                eventKey={
                  "https://looksrare.org/collections/0x08bEBEB5f042CCbaEb128582DA560cb25a5dB7e9"
                }
              >
                <img src="/images/svg/social.svg" alt="Opensea" />
              </Nav.Link>
            </Nav.Item>
          </div>
        </div>

        <div className="contract">
          Verified Smart Contract Address:
          <br />
          {CONTRACT_ADDRESS}
        </div>
      </Container>
    </div>
  );
}

export default Footer;
