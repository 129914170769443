import React, { Component } from "react";
import "./elders-container.scss";
import { Container, Row, Col } from "react-bootstrap";
import ElderCard from "./elder-card/elder-card";
import alison from "./alison.png";
import decentraland from "./decentraland.png";
import pixart from "./pixart.png";
import meta_key from "./meta_key.png";
import run_it_wild from "./run_it_wild.png";
import metaverse_studio from "./metaverse_studio.png";
import nft_studio from "./nft_studio.png";
import sandbox from "./assets/sandbox.jpg";

import img1 from "./assets/img-1.png";
import img2 from "./assets/img-2.png";
import img3 from "./assets/img-3.png";
import img4 from "./assets/img-4.png";
import img5 from "./assets/img-5.png";
import img6 from "./assets/img-6.png";
import img7 from "./assets/img-7.png";
import img8 from "./assets/img-8.png";

class ElderContainer extends Component {
  render() {
    return (
      <Container
        className="elders-container d-flex flex-column justify-content-center"
        fluid={true}
      >
        <div className="title">The Elders</div>

        <div className="elder-cards">
          <ElderCard image={img1} />
          <ElderCard image={img2} />
          <ElderCard image={img3} />
          <ElderCard image={img4} />
          <ElderCard image={img5} />
          <ElderCard image={img6} />

          <ElderCard image={img7} />
          <ElderCard image={img8} />
        </div>
      </Container>
    );
  }
}

export default ElderContainer;
