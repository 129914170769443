import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./update.scss";
import bottomImg from "./bottom.png";
import topImg from "./top.png";
import { HeaderTitle } from "../shared/HeaderTitle";

class Update extends Component {
  render() {
    return (
      <div className="update-page">
        <div className="top">
          <div className="bottom"></div>
        </div>
        <Container className="update-container container">
          <HeaderTitle title="Latest Update" />
          <div className="roadmap">
            <img className="img-top" src={topImg} />
            <div className="rm-left">
              <div className="item-left left-1">
                <div className="text">Discord Clan Creation</div>
                <div className="line-1"></div>
              </div>
              <div className="item-left left-2">
                <div className="text">Quest #1 : The Great Escape</div>
                <div className="line-1"></div>
              </div>
              <div className="item-left left-3">
                <div className="text">
                  RR After Party with Alison Wonderland, Valentino Khan & Elohim
                </div>{" "}
                <div className="line-1"></div>
              </div>
              <div className="item-left left-4">
                <div className="text">BYO Hood Wearable with BYO Pills</div>
                <div className="line-1"></div>
              </div>
              <div className="item-left left-5">
                <div className="text">
                  Alison Wonderland Set at Metaverse Festival
                </div>
                <div className="line-2"></div>
              </div>
              <div className="item-left left-6">
                <div className="text">Quest 1-4 POAPs & Rewards</div>
                <div className="line-2"></div>
              </div>
            </div>
            <div className="rm-right">
              <div className="item-right right-1">
                <div className="line-1"></div>
                <div className="text">Metakey Robe Wearable</div>
              </div>
              <div className="item-right right-2">
                <div className="line-1"></div>
                <div className="text">
                  Hatching Event with Alison Wonderland
                </div>
              </div>
              <div className="item-right right-3">
                <div className="line-1"></div>
                <div className="text">Quest #2: Wonderpotion</div>
              </div>
              <div className="item-right right-4">
                <div className="line-1"></div>
                <div className="text">Quest #3: Wonderflames & POAPs</div>
              </div>
              <div className="item-right right-5">
                <div className="line-2"></div>
                <div className="text">Quest #4: Wondersound</div>
              </div>
              <div className="item-right right-6">
                <div className="line-2"></div>
                <div className="text">Exclusive Album Listening</div>
              </div>
            </div>
            <img className="img-bottom" src={bottomImg} />
          </div>
        </Container>
      </div>
    );
  }
}

export default Update;
