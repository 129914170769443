import React, { useState } from "react";
import "./breeds.scss";
import { Container } from "react-bootstrap";
import image1 from "./assets/Standard_Western_Egg.png";
import image2 from "./assets/Traditional_Egg.png";
import image3 from "./assets/Tundra_Egg.png";
import image4 from "./assets/Wyvern_Egg.png";
import image5 from "./assets/Hydra_Egg.png";
import image6 from "./assets/Firedrake_Egg.png";
import image7 from "./assets/Kirin_Egg.png";
import image8 from "./assets/Black_Egg.png";
import image9 from "./assets/Basilisk_Egg.png";
import image10 from "./assets/Cockatrice_Egg.png";
import image11 from "./assets/Quetzalcoatl_Egg.png";
import image12 from "./assets/Luck_Egg.png";
import eggEmty from "./egg.png";
import { HeaderTitle } from "../shared/HeaderTitle";
import { List } from "antd";

const listImages = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
];

const LIST_EGGS = [
  {
    title: "Standard Western Egg",
    pound: 8.1,
    quantity: 12,
    description:
      "A rusty brown, with a slightly textured feel to the surface, it reminds\n" +
      "me of clay. To find this egg, I ventured across the burning sands of\n" +
      "the Endless Desert to the Kingdom of Salodom. The heat there is\n" +
      "oppressive; in the blistering midday sun anything left without shade\n" +
      "will perish in a matter of minutes. The great City of Salodomis sits\n" +
      "within these rolling hills of burnt red sand, an oasis of beauty and\n" +
      "wealth. Merchants from across the Seven Realms meet here to\n" +
      "exchange spices and trade stories of treasure, legend, and myth. It\n" +
      "was here that I heard rumours of the egg, resting atop a sand-dune\n" +
      "of immense size located leagues beyond the city walls. To spend a\n" +
      "single day in the desert means certain death - no camel can\n" +
      "withstand the midday sun. The walk took five. Finding this egg nearly\n" +
      "killed me, but I am certain its contents were worth the risk.",
    index: 1,
  },
  {
    title: "Traditional Egg",
    pound: 8.4,
    quantity: 11.5,
    description: `A vibrant green, the colour of meadows after the spring rains,
        jagged spikes protrude from the top of the egg, a warning sign to any who try
        to handle it. One could mistake it for a precious sapphire, although the contents,
        I suspect, are far more valuable than the gem with which it shares its colouring.
        This egg comes from the wet tropical jungles of Shanzin, where ancient trees tower
        towards the blazing sun in search of light, creating a lush canopy for life below.
        Vines dangle hundreds of yards from the tops of the trees to the jungle floor, and
        four limbed creatures swing playfully between them. Giant insects, the size of my arm,
        buzz loudly past. Everything is damp, humid, and full of life. Buried toward the centre
        of this loud and lively place I found an abandoned temple, covered by thick foliage.
        The walls inside were adorned with brightly coloured murals, as if they had just been
        painted. Scenes of royalty and depictions of heaven. The air here was thick and powerful,
        but not threatening; it felt as if it was guarded by a benevolent force. It almost
        felt as if the egg wanted me to find it; it willed me to take it.`,
    index: 2,
  },
  {
    title: "Tundra Egg",
    pound: 8.2,
    quantity: 11,
    description: `A brilliant glowing blue, covered with sharp shards of ice. The egg comes from deep in the high North, far past the Grey Wall. The snow there is heavy; the ground frozen since the earth was born. Nothing grows in this bitter wasteland, and what lives there is brutal and merciless. For eight months of the year, the land is cloaked in darkness. Packs of fearsome wolves with ruby eyes hunt relentlessly anything that moves. Mother Earth long ago fled this place, leaving it to wither and fade into a twilight realm, cold and hopeless. I will not return. `,
    index: 3,
  },
  {
    title: "Wyvern Egg",
    pound: 7.3,
    quantity: 10.5,
    description:
      "A royal blue, the same colour as the Queen’s flag, this egg is slightly\n" +
      "smaller than the rest but no less compelling. The egg itself was\n" +
      "plucked from the treacherous W Ocean off the coast of Draig Aur. A\n" +
      "place of uncommon beauty, where stone cliffs rise directly up from\n" +
      "the ocean floor as barren rocky outcrops, and waves the size of\n" +
      "mountains crash relentlessly against their unforgiving faces. The\n" +
      "storms here are terrible, with bolts of lightning streaking across the\n" +
      "charcoal sky and claps of thunder tearing through the sheets of rain\n" +
      "which fall constantly on the inky sea. As I sailed across the White\n" +
      "Ocean a fierce storm threatened to tear our vessel apart; waves tall\n" +
      "enough to cover the moon crashed across the deck and I was swept\n" +
      "overboard and pulled below the surface by a terror of the deep. I\n" +
      "awoke, some days later, ashore an island on a calm spring day,\n" +
      "clutching the egg, with no memory of my ordeal. I believe it to be a\n" +
      "ferocious creature inside, although I am not certain of its intentions.",
    index: 4,
  },
  {
    title: "Hydra Egg",
    pound: 10,
    quantity: 7.5,
    description:
      "A striking dark ash, highlighted with maroon streaks, the egg is\n" +
      "particularly interesting as it is misshapen; a large bulbous protrusion\n" +
      "breaks its otherwise perfectly spherical form. The egg itself comes\n" +
      "from a temple found atop Mount Olympus, high above the clouds, a\n" +
      "place where the Gods once played games of chance with the lives of\n" +
      "humans far below. Nestled deep within this mountain sanctuary,\n" +
      "hidden behind an impressive marble monolith, the egg was wrapped\n" +
      "gently in a silken sheet. I felt a presence watching me whilst there,\n" +
      "and left quickly.",
    index: 5,
  },
  {
    title: "Firedrake Egg",
    pound: 8.1,
    quantity: 5.5,
    description:
      "A vivid red, with a network of cracks revealing a fiery centre of\n" +
      "glowing embers. The egg is hot to the touch and must be handled\n" +
      "with care. I discovered it surrounded by flowing streams of lava in the\n" +
      "heart of the Red Mountain, a place far to the East where the\n" +
      "blackened sky is painted a molten red at dawn and dusk and fire falls\n" +
      "like rain across the ashen ground. The land here is desolate; strewn\n" +
      "with the burnt corpses of ancient trees. Nothing lives. Lava flows\n" +
      "relentlessly from the core of the Red Mountain, and everything it\n" +
      "touches is incinerated - it melts or burns or simply vanishes - but this\n" +
      "egg did not. It sat, impervious to the heat and the fire and the\n" +
      "destruction all around. I sense a great and terrible power within it.",
    index: 6,
  },
  {
    title: "Kirin Egg",
    pound: 8.1,
    quantity: 8.5,
    description:
      "A beautiful mixture of white and yellow, like honeycomb and fresh\n" +
      "milk, this egg feels regal, powerful and serene all at once. This egg I\n" +
      "found in the Flooded Forest of Kinjo, some nine months journey from\n" +
      "here, if you were to walk directly towards the evening star. The forest\n" +
      "is thick with bamboo, taller than castle spires, densely packed and\n" +
      "full of life. As I entered, a hush fell across the giant reeds. A place of\n" +
      "immense power, but not evil. I did not feel threatened, but rather\n" +
      "judged. Something watched me and followed my journey through the\n" +
      "Forest, not seeking to harm me, simply to protect the balance of life\n" +
      "within the trees. Several times I heard the sound of hoofs dancing,\n" +
      "but never once could I find trace of another living being; not a single\n" +
      "blade of grass was broken. The egg feels special to me, sacred and\n" +
      "pure. I believe the creature within to be good but capable of immense\n" +
      "shows of force toward those with ill-intent. Tread carefully.",
    index: 7,
  },
  {
    title: "Black Egg",
    pound: 9.2,
    quantity: 4.5,
    description:
      "A deathly black, deep as onyx, punctuated by stunning veins of deep\n" +
      "purple. It is hard to weigh, or even fix your eyes upon its shape as it\n" +
      "seems to absorb all daylight into its shell. It is like a black hole at the\n" +
      "centre of the universe, drawing all your senses inescapably toward it.\n" +
      "Light seems duller, sounds muted, and time slowed when in its\n" +
      "presence. The very weight of it seems to multiply; I cannot hold it for\n" +
      "more than a few seconds before my arm becomes weak and\n" +
      "lethargic. The egg itself comes from a wicked and evil place, far\n" +
      "beyond the walls of the great Kingdom of Faragone; an endless\n" +
      "mass of ancient trees and tangled vines, mossy stones, and wicked\n" +
      "creatures, known as the Black Forest. None who venture there\n" +
      "return. I trekked across this vile place, full of life but also death, for\n" +
      "twelve long days and nights before coming to the egg. Eyes watched\n" +
      "me constantly; thousands of them. Things would pull at my hair and\n" +
      "the haunting call of a banshee followed me through every waking\n" +
      "hour. I did not sleep. Never before have I felt the peril I experienced\n" +
      "in this place. This egg contains a powerful being.",
    index: 8,
  },
  {
    title: "Basilisk Egg",
    pound: 7.6,
    quantity: 6.5,
    description:
      "A bleak and lifeless grey, much like a serpent’s egg, tempered with\n" +
      "random strikes of emerald green. This egg was found deep within the\n" +
      "ancient tunnels under the ruined castle of Hellström, a place long\n" +
      "forgotten, where the air is stale and heavy. Thick stone walls track\n" +
      "steadily down into the bowels of the earth; the route to the bottom\n" +
      "twists and turns and turns again. It is said thousands have perished\n" +
      "in the passages there, searching for the grave of King Hulder,\n" +
      "rumoured to have been buried in his crypt with all his fabulous\n" +
      "wealth. It is deathly quiet there, but a dull hiss followed me, echoing\n" +
      "off the limestone. The egg is beautiful, but gazing upon it for too long\n" +
      "fills me with a sense of dread.",
    index: 9,
  },
  {
    title: "Cockatrice Egg",
    pound: 6.66,
    quantity: 9.5,
    description:
      "Charcoal black, the colour of a stormy Midsommer sky, tender purple\n" +
      "patches are revealed beneath its stony surface. The egg could easily\n" +
      "be mistaken for a piece of stone upon a rocky beach. And in fact, it\n" +
      "almost was. I found this egg whilst trekking in the North West, along\n" +
      "the barren shores of Lake Avenclaw, where the water meets the land\n" +
      "with a thunderous roar and giant trunks of fallen trees, petrified and\n" +
      "turned ghostly white, float silently along the coast. An eerie place,\n" +
      "windswept and desolate, but hauntingly beautiful. Everything there is\n" +
      "hard; rocky beaches turn into rocky cliffs turn into rocky mountains\n" +
      "which tower over the endless lake. I found myself there, following\n" +
      "whispers I heard of rare stones which stay warm despite the cold air.\n" +
      "This place was but shades of black and grey and white, and were it\n" +
      "not for the specks of brown and red splattered across its shell, I\n" +
      "would never have stumbled across the egg. When I did, I understood\n" +
      "the rumours. The egg feels and looks like stone, but radiates warmth\n" +
      "from deep within. My hand feels cold and hard when I hold it and I\n" +
      "will be happy to see it go.",
    index: 10,
  },
  {
    title: "Quetzalcoatl Egg",
    pound: 7.65,
    quantity: 10,
    description: `A dark green egg, the colour of the pine leaves strewn across the woodland floor just beyond the walls of the town. Fiery red tufts of fur breach the
        surface. This egg I found in the Inzcot mountains; giant peaks that rise far above the jungle beneath, summiting high above the clouds. Ancient, spiritual peoples
        dwell there, who still practice dark and sorcerous magic; sacrificing animals and trespassers alike to the Gods upon blocks of stone while rivers of scarlet blood
        run thickly across the ground. At daybreak, a fiery red star would rise at dawn and, for a moment, paint the temple in a crimson hue. One morning, the star shone
        directly upon the altar of the High Priestess, revealing the shape of the egg to me. I took it quickly, without thinking, and left. Spears followed me, whistling
        past my ears as I descended the mountain and ran through the jungle. I hid for several days before making my way back along the Amhzun River. I cannot tell if the
        creature within is good or evil, but the place from which it comes was a place of birth and a place of death. I am glad to have gone, and gladder still to not
        return.`,
    index: 11,
  },
  {
    title: "Luck Egg",
    pound: 8.88,
    quantity: 3,
    description: `This egg is the strangest of all; honestly, I have trouble bringing myself to sell it. The egg is covered in soft, pure white,
        fur. It is almost impossible to put down; whenever I am holding the egg, it is almost as if good things happen to me. I can’t explain it.
        Finding this egg was a combination of fortune and chance - if only all my adventures were so pleasant. I was travelling west, following the
        soft meadows of Gwyndor; picking daisies and Wolfsbane, content with the world and my place in it, when the feeling struck me. For several
        days I walked, as if in a trance. I drank from bubbling brooks and slept soundly in the warm air. On the fifth day, I came across it - by
        luck, by chance, by something else? I shall never know. Under a giant tree, beside a field of pastel flowers; a nest of the same soft white
        fur as the egg. A quiet breeze blew across my face and without thinking, I reached down and took the egg. For many days after this I was
        energetic. I felt the warmth of satisfaction spread across my cheeks. If I knew how to return, I would surely spend my dying days in that
        safe and pure place.`,
    index: 12,
  },
];

function Breeds() {
  const [eggActive, setEggActive] = useState(7);
  return (
    <div className="breeds-page">
      <div className="top">
        <div className="bottom"></div>
      </div>
      <Container className="breeds-container container">
        <HeaderTitle title="The Breeds" />
        <div className="banner-top">
          <div className="des">
            The merchant continued, his voice low, “Amongst my ten thousand
            eggs, I am sure that I have captured all twelve breeds. I know, too,
            that each of these dragons will be as unique as a snowflake in
            midwinter - no two are alike, and they never have been. And finally,
            I know that within these twelve breeds are three distinct classes of
            dragon. The Standard colour, as old and wise as time itself. The
            Technicolour, in all the colours of the rainbow, and, rarest of all,
            The Gold, - impossibly beautiful, as if forged in the great smelts
            of Faragone. All I have to share with you is my notes on each egg -
            it will be up to you if you hatch them to reveal the creature
            within, or savour them, unhatched, for your collection.”
          </div>
          <div className="egg-empty">
            <img className="egg-left" src={eggEmty} />
            <img className="egg-center" src={eggEmty} />
            <img className="egg-right" src={eggEmty} />
          </div>
        </div>

        <div className="item-select">
          <div className="infor">
            <div className="data">
              <div className="index">
                {LIST_EGGS[eggActive].index} OF 12 MYSTERIOUS DRAGON EGGS
              </div>
              <div className="name">{LIST_EGGS[eggActive].title}</div>
              <div className="note">
                Merchant’s Notes &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div className="weight-rarity">
                <div className="text">Weight:</div>
                <div>{LIST_EGGS[eggActive].pound} pounds</div>
              </div>
              <div className="weight-rarity">
                <div className="text">Rarity:</div>
                <div>
                  {LIST_EGGS[eggActive].quantity}% of eggs contain this breed
                </div>
              </div>
              <div className="des-egg">{LIST_EGGS[eggActive].description}</div>
            </div>
          </div>
          <img src={listImages[eggActive]} />
        </div>

        <div className="text-select-egg">Select an egg to read more</div>
        <div className="list-eggs">
          <List
            grid={{
              gutter: 24,
              xs: 2,
              sm: 2,
              md: 3,
              lg: 3,
              xl: 4,
              xxl: 4,
            }}
            dataSource={listImages}
            renderItem={(item, index) => (
              <List.Item>
                <img
                  src={item}
                  onClick={() => {
                    console.log("index:", index);
                    setEggActive(index);
                    // window.scrollTo(500, 0)
                    window.scrollTo({
                      top: 600,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                />
              </List.Item>
            )}
          />
        </div>
      </Container>
    </div>
  );
}

export default Breeds;
