import React, { useCallback, useEffect, useState } from "react";
import "./home.scss";
import PromiseContainer from "../promise-container/promise-container";
import eggEmty from "./background/egg-bg.png";
import buttonBuy from "./assets/buy-on-opensea.png";
import rightImage from "./assets/right-section-4.png";
import Web3 from "web3";

import EldersContainer from "../elders-container/elders-container";
import Loadingcomponent from "../loadingcomponent";
import { ABI_ARRAY, CONTRACT_ADDRESS } from "../../configs/contract";
import { HeaderTitle } from "../shared/HeaderTitle";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

let web3;
let NFTSmartContract;
// let remainingTokens;
let claimPrice;

const Home = (props) => {
  const [sendNumber, setSendingNumber] = useState(1);
  const [totalRemainNumber, setTotalRemainNumber] = useState(10000);
  const [isLoading, setIsLoading] = useState(false);

  const widthScreen = window.innerWidth;

  const loadWeb3 = useCallback(async () => {
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
    }
    if (web3) {
      NFTSmartContract = new web3.eth.Contract(ABI_ARRAY, CONTRACT_ADDRESS);
      // console.log({ NFTSmartContract })
      const addresses = await web3.eth.getAccounts();
      const senderAddress = addresses[0];
      console.log(senderAddress);
      NFTSmartContract.methods
        .totalSupply()
        .call({
          from: senderAddress,
        })
        .then((currentSupply) => {
          console.log({ currentSupply });
          setTotalRemainNumber(10000 - currentSupply);
        });
      NFTSmartContract.methods
        .getClaimPrice()
        .call({
          from: senderAddress,
        })
        .then((contractClaimPrice) => {
          console.log({ contractClaimPrice });
          claimPrice = contractClaimPrice;
        });
    } else {
      window.alert(
        "Not connected to a Web3 Wallet! Please install/connect to Metamask on your browser."
      );
    }
  });

  const loadWeb3ForPage = useCallback(async () => {
    setIsLoading(true);
    await loadWeb3();
    setIsLoading(false);
  }, [loadWeb3, props]);

  useEffect(() => {
    // loadWeb3ForPage();
  }, []);

  const handleSoldoutClick = useCallback(async () => {
    console.log("Test");
    await loadWeb3();
    const addresses = await web3.eth.getAccounts();
    const senderAddress = addresses[0];
    setIsLoading(true);
    NFTSmartContract.methods
      .claimEggs(sendNumber)
      .send({
        from: senderAddress,
        value: sendNumber * claimPrice,
      })
      .on("sent", function (send) {
        setIsLoading(true);
      })
      .on("receipt", function (receipt) {
        setIsLoading(false);
        window.alert("Success: Eggs Claimed. You can now headover to OpenSea!");
        loadWeb3();
      })
      .on("error", function (error) {
        setIsLoading(false);
        window.alert("Error: " + JSON.stringify(error.message));
      });
  }, [sendNumber, setIsLoading, isLoading]);

  if (isLoading) return <Loadingcomponent />;
  return (
    <div className="main-page">
      <ParallaxProvider scrollAxis="vertical">
        <Parallax disabled={widthScreen < 1200} slowerScrollRate={true}>
          <div className="section-1">
            <video autoPlay loop muted playsInline>
              <source
                src="https://wonderquest-assets.s3.ap-southeast-2.amazonaws.com/video/wonderquest-animation.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </Parallax>

        <div className="section-2">
          <div className="bg-right"></div>
          <div className="content container">
            <Parallax
              disabled={widthScreen < 1200}
              speed={-20}
              slowerScrollRate={true}
            >
              <div className="title-section-2">Your Quest Starts Here</div>
              <div className="des-section-2">
                The WonderQuest is an immersive exploration of Alison
                Wonderland’s virtual world of dragons and dark arts.{" "}
              </div>
            </Parallax>
          </div>
        </div>
        <Parallax
          disabled={widthScreen < 1200}
          speed={-20}
          slowerScrollRate={true}
          tag="figure"
        >
          <div className="section-3 container">
            <div className="content-section-3">
              <HeaderTitle title="Utility" />
              <div className="des">
                Every holder of a dragon or egg will receive ongoing utility to
                the world of Alison Wonderland, including token-gated access to
                early music releases, unique merchandise and secret rewards.
                Plus, use your included admission to access virtual festivals in
                the metaverse, or participate in members-only questing to earn
                exclusive wearables.
              </div>
            </div>
            <div className="egg-empty">
              <img className="egg-left" src={eggEmty} />
              <img className="egg-center" src={eggEmty} />
              <img className="egg-right" src={eggEmty} />
              <a
                className="button-buy"
                href="https://opensea.io/collection/thewonderquest"
                target="_blank"
              >
                <img className="" src={buttonBuy} />
              </a>
            </div>
          </div>
        </Parallax>
        <Parallax
          disabled={widthScreen < 1200}
          speed={20}
          slowerScrollRate={true}
          tag="figure"
        >
          <div className="section-4 container">
            <div className="bg-section-4">
              <div className="content-section-4">
                <div className="title-section-4">
                  “FMUOASL in the Metaverse”
                </div>
                <div className="des-section-4">
                  <div className="left">
                    Hey Wonderers,
                    <br />
                    <br />
                    Thank you for being here.. I'm super excited for this! I've
                    always been fascinated with alternate realities. I grew up
                    watching fantasy films and am grateful to have the chance to
                    build in the metaverse.
                    <br />
                    <br />
                    <br />I like the idea of web3 because from what I can see it
                    allows people to be creatively free. I want to do things
                    that I can't do in the real world. Like wearing my robe
                    everywhere, haha!
                    <br />
                    <br />
                    Another thing that I'm pumped about is community. I'm a big
                    advocate in the mental-health space. If you've been to my
                    live shows you've probably heard me speak about it.
                    Non-judgement zones are important to me, love everyone for
                    who they are.
                    <br />
                    <br />
                    The dragons are only the beginning. I'll be playing virtual
                    sets, providing early access to my releases, and more epic
                    stuff. Join me in this adventure, and together we quest.
                    <div className="des-bottom">
                      From,
                      <br />
                      Alison Wonderland
                    </div>
                  </div>
                  <div className="right">
                    <img src={rightImage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>

        <div className="section-5">
          <Parallax
            disabled={widthScreen < 1200}
            speed={20}
            slowerScrollRate={true}
            tag="figure"
          >
            <div className="section-5-1">
              <div className="title-section-5 container">
                Explore the Wonders of the World
              </div>
              <PromiseContainer />
            </div>
          </Parallax>
          <div className="section-5-2">
            <EldersContainer />
          </div>
        </div>
        <div className="section-6">
          <div className="bg-section-6"></div>
        </div>
      </ParallaxProvider>
    </div>
    // <div className="Main">
    //   <div className="container-fluid main-image-container">
    //     <div className="container pt-5">
    //       <img
    //         src={mainImage}
    //         alt="wonderquest-mainImage"
    //         className="mt-3 wonderquest-mainImage"
    //       />
    //     </div>

    //     <div id="intro-cage-wrap">
    //       <div className="intro-cage container position-relative">
    //         <div id="intro-cage-inner">
    //           <div>
    //             The WonderQuest is an immersive exploration of Alison
    //             Wonderland’s virtual world of dragons and black magic. It starts
    //             with a generative NFT project of ten thousand splendorous eggs,
    //             each containing that most rare and dangerous of creatures; a
    //             dragon. You can hatch your eggs to reveal your dragon’s unique
    //             traits, or collect them in their unhatched glory. To hatch or
    //             not to hatch; that choice is yours.
    //           </div>
    //           <div className="intro-cage-second">
    //             Go where none have gone before.
    //           </div>
    //           {/* <div>
    //                             <b>On-sale now 0.0888 ETH.</b>
    //                         </div> */}
    //           <div>
    //             <b>
    //               <a
    //                 class="link-text"
    //                 href="https://opensea.io/collection/thewonderquest"
    //                 target="_blank"
    //               >
    //                 Buy now on OpenSea
    //               </a>
    //             </b>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <Row xs={12} className="mt-5">
    //       <Container
    //         className="egg-container d-flex flex-column justify-content-center mt-0"
    //         fluid={true}
    //       >
    //         <Row xs={12}>
    //           <Col xs={12}>
    //             <h6>THE WONDERQUEST</h6>
    //           </Col>
    //         </Row>
    //         <Row xs={12}>
    //           <Col xs={12}>
    //             <h4>CHAPTER 1: THE HATCHING</h4>
    //           </Col>
    //         </Row>
    //         <div className="container">
    //           <p>
    //             The Wonderquest begins in the market, where a mysterious cloaked
    //             trader has for sale ten thousand stunning eggs, unlike any seen
    //             in Wondertown before. “Dragons,” he whispers in hushed tones to
    //             no-one in particular, as crowds of villagers hurry past, arms
    //             full of cabbage and potatoes and leeks. At first, few stop. But
    //             slowly, some do. For these lucky few, the merchant opens a door
    //             into a world unlike any other.
    //             <br></br>
    //             <br></br>
    //             Each egg contains a dragon. Each dragon, completely distinct
    //             from every other, comes from one of twelve ancient breeds. “I
    //             have travelled all my long life in search of wild and magical
    //             things,” he tells the small crowd now gathered in front of him.
    //             “In the twilight of my years, I now offer my most precious
    //             possessions, these eggs, to you.” The merchant holds in his
    //             withered hand a scroll, where he keeps notes about every single
    //             egg he is selling - its description, the story of where he found
    //             it, his feelings about what kind of majestic dragon may wait
    //             inside.
    //             <br />
    //             <br />
    //             “These aren’t just any old eggs,” he continues. “The ancient
    //             prophecy tells of an epic quest through the land, arriving at a
    //             sacred Temple of Wonderland. Inside, it says, a mighty Sorceress
    //             plays rhythmic, ethereal, hauntingly beautiful melodies to any
    //             who can discover the secret to entering her incredible shrine.
    //             Hearing this music, it is said, bestows upon you the power to
    //             hatch your eggs, if you so desire. However, if you’d rather not
    //             reveal the wild power within, you can leave your eggs untouched,
    //             unhatched; display them on your mantle for all to admire. That
    //             choice will be yours to make.”
    //             <br />
    //             <br />
    //             “The first step is taking some eggs from me, friend.”
    //           </p>
    //         </div>
    //         <Row xs={12} className="dragon-egg">
    //           <Col xs={12}>
    //             <img
    //               className="dragon-egg-image"
    //               src={dragonEgg}
    //               alt="dragonEgg"
    //             />
    //             {/* <p className="mb-1">
    //                                 <b>0.0888 ETH Per Egg.</b>
    //                                 <br />
    //                                 Total Eggs Remaining: {totalRemainNumber.toLocaleString()}
    //                             </p>
    //                             <div className="d-flex justify-content-center btns-area">
    //                                 <span className="minus-icon btn-icon" onClick={() => {
    //                                     if (sendNumber === 1) return;
    //                                     setSendingNumber(sendNumber - 1)
    //                                 }}></span>
    //                                 <span>{sendNumber}</span>
    //                                 <span className="plus-icon btn-icon" onClick={() => {
    //                                     if (sendNumber === 10) return;
    //                                     setSendingNumber(sendNumber + 1)
    //                                 }}></span>
    //                             </div> */}
    //             <p class="mb-1 normal-text">
    //               <b>
    //                 <a
    //                   class="link-text"
    //                   href="https://opensea.io/collection/thewonderquest"
    //                   target="_blank"
    //                 >
    //                   Head to OpenSea to purchase on the secondary market.
    //                 </a>
    //               </b>
    //             </p>
    //             {/*<Button size="lg" className="mint-egg-btn mt-0" onClick={()=>{}}><span>COMING SOON</span></Button>*/}
    //             {/* <Button size="lg" className="mint-egg-btn mt-0" onClick={handleSoldoutClick}><span>MINT</span></Button> */}
    //             <Button size="lg" className="mint-egg-btn mt-0">
    //               <span>SOLD OUT</span>
    //             </Button>
    //           </Col>
    //         </Row>
    //       </Container>
    //     </Row>
    //   </div>
    //   <PromiseContainer promises={promiseData} />
    //   <EldersContainer />
    // </div>
  );
};

Home.propTypes = {};

export default Home;
