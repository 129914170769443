import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import Nav from "react-bootstrap/Nav";
import logo from "./logo.svg";
import connectWallet from "./connect-wallet.svg";
import discord from "./discord.svg";
import twitter from "./twitter.svg";
import { NavLink, Link, useLocation } from "react-router-dom";
import "./header.scss";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import { isMobile, isTablet } from "react-device-detect";
import { toast, ToastContainer } from "react-toastify";
import { InjectedConnector } from "@web3-react/injected-connector";

const navs = [
  // {
  //   label: "Home",
  //   link: "/home",
  // },
  {
    label: "Map",
    link: "/map",
  },
  {
    label: "Breeds",
    link: "/breeds",
  },
  {
    label: "Hatch",
    link: "/hatch",
  },
  {
    label: "The Temple",
    link: "/update",
  },
  // {
  //   label: "Quests",
  //   link: "/quests",
  //   locked: true,
  // },
  {
    label: "Light Paper",
    link: "/lightpaper",
  },
  // {
  //   label: "Loner",
  //   link: "https://www.thewonderquest.io/",
  // },
];

function Header() {
  const location = useLocation();
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);

  const web3ReactContext = useWeb3React();
  const { account, chainId, activate, active, error, library, deactivate } =
    web3ReactContext;

  const networkStatus = {
    chainId: 1,
    ethChainId: "0x1",
    messageErr: "Please connect to Ethereum Main Network.",
  };

  // const injected = new InjectedConnector({
  //   supportedChainIds: [1],
  // });
  const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 42],
  });

  // useEffect(() => {
  //   console.log('testtttttttttt')
  //   const checkConnectWallet = window.localStorage.getItem("connectWallet");
  //   if (checkConnectWallet) activate(injected);
  // }, [activate, active, error]);

  const handleConnectWallet = async () => {
    const { ethereum } = window;

    if (isTablet || isMobile) {
      if (ethereum && ethereum.isMetaMask) {
        console.log(true);
      } else {
        return window.open("https://metamask.app.link/dapp/wonderquest.io");
      }
    }

    if (account) {
      return;
    }

    if (!ethereum) {
      return toast.error(
        "Non-Ethereum browser detected. Please install MetaMask plugin."
      );
    }

    if (error) {
      if (error.code === -32002) {
        return toast.error(error.message);
      }

      if (chainId !== networkStatus.chainId) {
        return toast.error(networkStatus.messageErr);
      }
    }

    // If the provider is not detected, we will check this case
    const ethChainId = await window.ethereum.request({
      method: "eth_chainId",
    });

    if (ethChainId !== networkStatus.ethChainId) {
      return toast.error(networkStatus.messageErr);
    }

    try {
      window.localStorage.setItem("connectWallet", true);
      activate(injected);
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleMenu = useCallback(() => {
    setIsOpenMenuMobile((prev) => !prev);
  }, [setIsOpenMenuMobile]);

  useLayoutEffect(() => {
    setIsOpenMenuMobile(false);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  const formatAccountDisplay = (address) => {
    if (address) {
      return `${address?.substr(0, 6)}...${address?.substr(-6, 6)}`;
    }
    return address;
  };

  useEffect(() => {
    const checkConnectWallet = window.localStorage.getItem("connectWallet");
    if (checkConnectWallet) activate(injected);
  }, [activate, active, error, chainId]);

  return (
    <div className="header">
      <Container>
        <Nav
          className={`header-nav ${isOpenMenuMobile ? "mobile-active" : ""}`}
          activeKey="/home"
        >
          <Nav.Item className="d-flex logo">
            <Link to="/home">
              <img src={logo} alt="WonderQuestLogo" />
            </Link>
          </Nav.Item>

          <div className="menu-item-wrapper">
            {navs.map((navItem) => {
              const className = "nav ms-2 me-2";

              if (navItem.locked) {
                return (
                  <div className={`${className} locked`}>
                    <div className="nav-item">
                      <a href="javascript:void(0)" className="nav-link">
                        {navItem.label}
                        {navItem.locked && (
                          <img
                            className="lock"
                            src="/images/lock.svg"
                            alt="locked"
                          />
                        )}
                      </a>
                    </div>
                  </div>
                );
              }

              return (
                <NavLink
                  key={navItem.link}
                  exact
                  to={navItem.link}
                  activeClassName="active"
                  className={className}
                >
                  <Nav.Item>
                    <Nav.Link href={navItem.link} eventKey={navItem.link}>
                      {navItem.label}
                    </Nav.Link>
                  </Nav.Item>
                </NavLink>
              );
            })}
            <a className="nav ms-2 me-2">
              <div className="nav-item">
                <a className="nav-link" href="https://www.thewonderquest.io/" target="_blank">
                  Loner
                </a>
              </div>
            </a>
            <div className="d-flex header-icons ms-5 me-5">
              <Nav.Item className="d-flex discord-icon">
                <Nav.Link
                  href={"http://discord.gg/thewonderquest"}
                  target={"_new"}
                  eventKey={"http://discord.gg/thewonderquest"}
                >
                  <img className="icon-img" src="/images/svg/discord.svg" alt="Discord" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="d-flex twitter-icon">
                <Nav.Link
                  href={"https://twitter.com/thewonderquest"}
                  target={"_new"}
                  eventKey={"https://twitter.com/thewonderquest"}
                >
                  <img className="icon-img" src="/images/svg/twitter.svg" alt="Twitter" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="d-flex twitter-icon">
                <Nav.Link href="https://opensea.io/collection/thewonderquest" target={"_new"} eventKey={"https://opensea.io/collection/thewonderquest"}>
                  <img className="icon-img" src="/images/svg/opensea.svg" alt="Opensea" />
                </Nav.Link>
              </Nav.Item>
            </div>
          </div>

          {/* menu mobile trigger */}
          <div className="mobile-trigger" onClick={toggleMenu}>
            <img src="/images/menu.svg" alt="open menu" className="open-menu" />
            <img
              src="/images/close.svg"
              alt="close menu"
              className="close-menu"
            />
          </div>

          <div className="d-flex connect-button-wrapper">
            <Button
              size="sm"
              type="button"
              className="connect-wallet-btn wallet-btn"
              onClick={handleConnectWallet}
            >
              {/* Connect Wallet */}
              {library ? (
                formatAccountDisplay(account)
              ) : (
                <img src={connectWallet} />
              )}
            </Button>
          </div>
        </Nav>
      </Container>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        theme="light"
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Header;
